import { useStaticQuery, graphql } from 'gatsby';

export const useAllPodcasts = () => {
  const data = useStaticQuery(graphql`
    query AllPodcastsQuery {
      allContentfulPodcastEpisode {
        edges {
          node {
            featuredImage {
              gatsbyImageData(width: 640, aspectRatio: 1)
            }
            title
            url
          }
        }
      }
    }
  `);
  return data;
};
