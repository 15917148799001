import React, { useEffect } from 'react';

import * as css from './Twitter.module.css';

export default function Twitter() {
  function appendTwitter() {
    const url = 'https://platform.twitter.com/widgets.js';
    const script = document.createElement('script');
    script.src = url;
    script.setAttribute('charset', 'utf-8');
    document.body.appendChild(script);
  }

  useEffect(appendTwitter, []);

  return (
    <aside className={css.Twitter}>
      <a
        className="twitter-timeline"
        href="https://twitter.com/selectedwisdom?ref_src=twsrc%5Etfw"
      >
        Tweets by selectedwisdom
      </a>
    </aside>
  );
}
