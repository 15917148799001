import { useQuery } from 'react-query';
import fetcher from '../utils/fetcher';
import substackParser from '../utils/substackParser';

const LIMIT = 3;
const STACKS_ENDPOINT = `${process.env.GATSBY_NETLIFY_BASE}/fetchStacks`;

export function useSubstack(limit = LIMIT) {
  const { data, isLoading } = useQuery(
    'stacks',
    fetcher(STACKS_ENDPOINT, 'text')
  );
  const allStacks = substackParser(data);
  const stacks = allStacks ? allStacks.slice(0, limit) : [];

  return { stacks, isLoading };
}
