import React from 'react';
import { useSubstack } from '@hooks';
import createMarkup from '@utils/createMarkup';
import * as css from './Substack.module.css';

const SUBSTACK_URL = 'https://clintwatts.substack.com/';

function Stack({ post }) {
  return (
    <article className={css.Stack}>
      <figure style={{ backgroundImage: post?.image }} />
      <section className={css.Details}>
        <h3 dangerouslySetInnerHTML={createMarkup(post?.title)} />
        <p dangerouslySetInnerHTML={createMarkup(post?.description)} />
        {post?.author && (
          <a
            className={css.Author}
            href={post?.authorUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <small dangerouslySetInnerHTML={createMarkup(post?.author)} />
          </a>
        )}
        <a href={post?.url} target="_blank" rel="noopener noreferrer">
          Read Full Article
        </a>
      </section>
    </article>
  );
}

export function Substack({ limit }) {
  const { stacks, isLoading } = useSubstack(limit);
  if (isLoading)
    return (
      <div className={css.StacksLoading}>Fetching the latest posts...</div>
    );
  return (
    <section className={css.Stacks}>
      <header>
        <h2>Recent Articles</h2>
      </header>
      {stacks.map((stack) => (
        <Stack post={stack} key={stack?.url} />
      ))}
      <a
        className={css.ViewAll}
        href={SUBSTACK_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        View All Articles
      </a>
    </section>
  );
}

Substack.defaultProps = {
  limit: 3,
};
