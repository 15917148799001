import React from 'react';
import * as Mods from './Modules';

export const Embeds = ({ embed }) => {
  if (embed === 'Podcast Episodes') return <Mods.PodcastEpisodes />;
  if (embed === 'YouTube and Twitter') return <Mods.YouTubeAndTwitter />;
  const Component = Mods[embed];
  if (!Component) return <div>Oh no! Something went wrong</div>;
  return <Component />;
};
