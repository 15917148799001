import React from 'react';
import Twitter from '@components/Twitter';
import YouTube from '@components/YouTube';

export const YouTubeAndTwitter = () => {
  return (
    <section className="grid">
      <section className="gc-8">
        <YouTube />
      </section>
      <aside className="gc-4">
        <Twitter />
      </aside>
    </section>
  );
};
