const AUTHOR = ".post-preview-author";
const DESC = ".post-preview-description";
const IMAGE = ".post-preview-image";
const PREVIEW = ".post-preview";
const TITLE = ".post-preview-title";

export default function substackParser(content) {
  if (!content) return null;
  const wrapper = document.createElement("div");
  wrapper.innerHTML = content;
  const postsHtml = Array.from(wrapper.querySelectorAll(PREVIEW));

  function parsePost(post) {
    const author = post?.querySelector(`${AUTHOR} a`)?.innerHTML;
    const authorUrl = post?.querySelector(`${AUTHOR} a`)?.href;
    const url = post?.querySelector("a")?.href;
    const image = post
      ?.querySelector(IMAGE)
      ?.getAttribute("style")
      ?.replace("background-image: ", "")
      ?.replace(";", "");
    const title = post?.querySelector(TITLE).innerHTML;
    const description = post?.querySelector(DESC).innerHTML;
    return {
      author,
      authorUrl,
      description,
      image,
      url,
      title,
    };
  }

  return postsHtml.map(parsePost);
}
