import React from 'react';
import { Embeds } from '@components/Embeds/Embeds';
import ContentfulText from '@components/ContentfulText';
import Text from '@components/Text';

export const Content = ({ content }) => {
  return (
    <>
      {content.map((item, i) => {
        const id = `${item?.id}-${i}` || Math.random();
        if (item?.embed) return <Embeds embed={item?.embed} key={id} />;
        if (item?.richText) {
          const textAlign = item?.textAlignment?.toLowerCase() || 'left';
          return (
            <Text style={{ textAlign }} key={id}>
              <ContentfulText text={item?.richText} />
            </Text>
          );
        }
        return null;
      })}
    </>
  );
};
