import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import ContentfulText from '@components/ContentfulText';

import * as css from './TeamPreview.module.css';
import { Link } from 'gatsby';

export default function TeamPreview({ person }) {
  return (
    <article className={css.Preview}>
      {person?.photo && (
        <aside className={css.Image}>
          <GatsbyImage
            image={getImage(person?.photo?.gatsbyImageData)}
            alt={person?.name}
          />
        </aside>
      )}
      <section className={css.Text}>
        <h2>{person.name}</h2>
        <ContentfulText text={person.excerpt} />
        <Link to={`/about/${person.slug}`}>Read More</Link>
      </section>
    </article>
  );
}
