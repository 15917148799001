import { graphql, useStaticQuery } from 'gatsby';

export function useTeam() {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      team: allContentfulTeamMember(sort: { fields: name }) {
        edges {
          node {
            id
            name
            slug
            biography {
              raw
            }
            photo {
              gatsbyImageData(width: 640, aspectRatio: 1)
            }
            excerpt {
              raw
            }
          }
        }
      }
    }
  `);
  return data?.team?.edges || [];
}
