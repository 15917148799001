import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useAllPodcasts } from '@lib';
import { Link } from 'gatsby';

export const PodcastEpisodes = () => {
  const eps = useAllPodcasts()?.allContentfulPodcastEpisode?.edges;
  if (!eps.length) return null;
  return (
    <section className="grid">
      {eps.map((ep) => (
        <Link
          key={ep.node.url}
          className="gc-12 md:gc-2 lg:gc-3 text-align-center block-gap-1"
          to={`/podcast/${ep.node.url}`}
        >
          <div>
            <GatsbyImage
              image={getImage(ep?.node?.featuredImage?.gatsbyImageData)}
              alt={ep.node.title}
            />
          </div>
          <h3>{ep.node.title}</h3>
        </Link>
      ))}
    </section>
  );
};
