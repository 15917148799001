import React from 'react';

import * as css from './YouTube.module.css';

export default function YouTube() {
  return (
    <section className={css.Container}>
      <h2>Latest Trust but Verify Videos on YouTube</h2>
      <section className={css.Wrapper}>
        <iframe
          className={css.Embed}
          src="https://www.youtube.com/embed/videoseries?list=PLtBZHojiCqnBnYXHeR3RKmXcIPuLjlvkx"
          title="Trust But Verify YouTube"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </section>
    </section>
  );
}
