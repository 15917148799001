import React from 'react';
import { useTeam } from '@lib';
import TeamPreview from '@components/TeamPreview';
export const Team = () => {
  const team = useTeam();

  return (
    <>
      {team.map((person) => (
        <TeamPreview person={person.node} key={person.node.id} />
      ))}
    </>
  );
};
