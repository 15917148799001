import React from 'react';
import { graphql } from 'gatsby';
import Section from '@components/Section';
import Seo from '@components/Seo';
import { Content } from '../components/Content/Content';

export default function SpecialPage({ data }) {
  return (
    <Seo title={data?.contentfulSpecialPages?.title}>
      <Section>
        <Content content={data?.contentfulSpecialPages?.content} />
      </Section>
    </Seo>
  );
}

export const query = graphql`
  query SpecialPageQuery($id: String!) {
    contentfulSpecialPages(id: { eq: $id }) {
      content {
        ... on ContentfulContentEmbed {
          id
          embed
        }
        ... on ContentfulContentTextBlock {
          id
          richText {
            raw
          }
          textAlignment
        }
      }
      title
    }
  }
`;
